import React,{useLayoutEffect} from 'react';
import "../CSS/Service.css";
import ServiceData from '../Component/ServiceData';
import Bounce from 'react-reveal/Bounce';
import Footer from "../Component/Footer";

const Services = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <>
      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

      <section className='Service-Section-1'>
        <div className='Service-Banner'>
          <h1>
            <Bounce top cascade>
              Service
            </Bounce>
          </h1>
        </div>
      </section>
      {/* ////////////////////////////// Section 2 //////////////////////////////// */}

      <section className='Service-Section-2'>
        <div className='container'>
          <div className='row'>
            {
              ServiceData.map((Data) => {
                const { ServiceName, ServiceSubName, ServiceImage } = Data
                return (
                  <>
                    <div className='col-md-4'>
                      <div className='Service-Card-Body'>
                        <div className='Service-Card'>
                          <div className='Service-Card-Image'>
                            <img src={ServiceImage} alt="" />
                          </div>
                          <div className='Services-Card-Overlay'>
                            <div className='Card-Name'>
                              {ServiceName}
                              <span>{ServiceSubName}</span>
                            </div>
                            <div className='Show-Line'>
                              <span></span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Services