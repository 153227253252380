import React, { useState } from 'react';
import "../CSS/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CiInstagram, CiYoutube } from 'react-icons/ci';
// import { AiOutlineYoutube, AiOutlineInstagram } from 'react-icons/ai';
import { RiWhatsappLine, RiFacebookFill, RiInstagramFill } from 'react-icons/ri';
import Logo from "../Images/WHATEVER EVENTS LOGO.png"



const NavBar = () => {

    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)


    return (
        <>


            <div className="nav-bar-component">
                <section className="NavSection">
                    <div className="container">
                        <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                            <Container>
                                {/* <Navbar.Brand href="#home">Logo</Navbar.Brand> */}
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Brand href="#home">
                                    <div className='NavLogo'>
                                        <img src={Logo} alt="" />
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                                    <Nav>
                                        <Nav.Link href="#home"> <Link to="/">Home</Link> </Nav.Link>
                                        <Nav.Link href="#Services"> <Link to="/Service">Services</Link> </Nav.Link>
                                        <Nav.Link href="#Contact us"> <Link to="/Gallery">Gallery</Link> </Nav.Link>
                                        <Nav.Link href="#About us"> <Link to="/AboutUs">About Us</Link> </Nav.Link>
                                        <Nav.Link href="#Contact us"> <Link to="/ContactUs">Contact Us</Link> </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                                <Nav className="Social-media-icons">
                                    <a href='https://wa.me/7039212103?text=' target="blank"><RiWhatsappLine /></a>
                                    <a href='https://www.facebook.com/profile.php?id=100088041936338&mibextid=LQQJ4d' target="blank"><RiFacebookFill /></a>
                                    <a href='https://www.instagram.com/whatever.events/' target="blank"><RiInstagramFill /></a>
                                </Nav>
                            </Container>
                        </Navbar>
                    </div>
                </section>

            </div>


        </>
    )
}

export default NavBar