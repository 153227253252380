import Birthday1 from "../Images/Events/Birthday Party 01.jpg";
import Birthday2 from "../Images/Events/Birthday Party 02.jpg";
import Corporate1 from "../Images/Events/Corporate 01.jpg";
import Corporate2 from "../Images/Events/corporate-events.jpg";
import Corporate3 from "../Images/Events/corporate-party.png";
import Family1 from "../Images/Events/Family Events 01.jpg";
import Party from "../Images/Events/Party.jpg";
import Private from "../Images/Events/Private 01.jpg";
import Wedding from "../Images/Events/Wedding 01.jpg";
import Anniversary from "../Images/Events/wedding Anniversary 01.jpg";

///////// Wedding Images //////////////////

import WeddingImage1_1 from "../Images/Gallery Images/Wedding1.1.jpg";
import WeddingImage1_2 from "../Images/Gallery Images/Wedding1.2.jpg";
import WeddingImage1_3 from "../Images/Gallery Images/Wedding1.3.jpg";
import WeddingImage1_4 from "../Images/Gallery Images/Wedding1.4.jpg";

////////////////// birthday ////////////////////

import BirthdayImages1_1 from "../Images/Gallery Images/Birthday1_1.jpg";
import BirthdayImages1_2 from "../Images/Gallery Images/Birthday1_2.jpg";
import BirthdayImages1_3 from "../Images/Gallery Images/Birthday1_3.jpg";
import BirthdayImages1_4 from "../Images/Gallery Images/Birthday1_4.jpg";
import BirthdayImages1_5 from "../Images/Gallery Images/Birthday1_5.jpg";
import BirthdayImages1_6 from "../Images/Gallery Images/Birthday1_6.jpg";
import BirthdayImages1_7 from "../Images/Gallery Images/Birthday1_7.jpg";
import BirthdayImages1_8 from "../Images/Gallery Images/Birthday1_8.jpg";
import BirthdayImages1_9 from "../Images/Gallery Images/Birthday1_9.jpg";
import BirthdayImages2_0 from "../Images/Gallery Images/Birthday2_0.jpg";


////////////////// Office Party ////////////////////

import OfficeParty1_1 from "../Images/Gallery Images/Office Party1_1.jpg";
import OfficeParty1_2 from "../Images/Gallery Images/Office Party1_2.jpg";
import OfficeParty1_3 from "../Images/Gallery Images/Office Party1_3.jpg";
import OfficeParty1_4 from "../Images/Gallery Images/Office Party1_4.jpg";
import OfficeParty1_5 from "../Images/Gallery Images/Office Party1_5.jpg";
import OfficeParty1_6 from "../Images/Gallery Images/Office Party1_6.jpg";
import OfficeParty1_7 from "../Images/Gallery Images/Office Party1_7.jpg";



const GalleryData = [
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:Birthday1,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_1,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_2,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_3,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_4,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_5,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_6,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_7,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_8,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages1_9,
        EventName:"Birthday Event",
    },
    {
        id:1,
        Category:"Birthday",
        title:"React Js Tutorial",
        EImage:BirthdayImages2_0,
        EventName:"Birthday Event",
    },
    {
        id:2,
        Category:"Corporate",
        title:"React Js Tutorial",
        EImage:Corporate1,
        EventName:"Corporate Event",
    },
    {
        id:3,
        Category:"Family",
        title:"React Js Tutorial",
        EImage:Family1,
        EventName:"Family Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:Party,
        EventName:"Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_1,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_2,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_3,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_4,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_5,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_6,
        EventName:"Office Party Event",
    },
    {
        id:4,
        Category:"Party",
        title:"React Js Tutorial",
        EImage:OfficeParty1_7,
        EventName:"Office Party Event",
    },
    {
        id:5,
        Category:"Private",
        title:"React Js Tutorial",
        EImage:Private,
        EventName:"Private Event",
    },
    {
        id:6,
        Category:"Wedding",
        title:"React Js Tutorial",
        EImage:Wedding,
        EventName:"Wedding Event",
    },
    {
        id:7,
        Category:"Wedding",
        title:"React Js Tutorial",
        EImage:WeddingImage1_1,
        EventName:"Wedding Event",
    },
    {
        id:8,
        Category:"Wedding",
        title:"React Js Tutorial",
        EImage:WeddingImage1_2,
        EventName:"Wedding Event",
    },
    {
        id:9,
        Category:"Wedding",
        title:"React Js Tutorial",
        EImage:WeddingImage1_3,
        EventName:"Wedding Event",
    },
    {
        id:10,
        Category:"Wedding",
        title:"React Js Tutorial",
        EImage:WeddingImage1_4,
        EventName:"Wedding Event",
    },
    {
        id:11,
        Category:"Anniversary",
        title:"React Js Tutorial",
        EImage:Anniversary,
        EventName:"Anniversary Event",
    },
]

export default GalleryData;