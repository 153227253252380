
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import NavBar from './Component/NavBar';
import HomePage from "./Pages/HomePage";
import Gallery from "./Pages/Gallery";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import Services from './Pages/Services';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/Service' element={<Services />} />
          <Route path='/Gallery' element={<Gallery />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/AboutUs' element={<AboutUs />} />
        </Routes>


      </BrowserRouter>

    </div>
  );
}

export default App;
