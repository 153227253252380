import WeddingPalner from "../Images/1-1.jpg";
import BirthdayPlanner from "../Images/2-1.jpg";
import SpecialEvent from "../Images/6.jpg"

import BirthdayParty from "../Images/Services/Birthday.jpg";
import ConferenceEvent from "../Images/Real Images/ConferenceEvent.jpg";
import AnniversaryPlanning from "../Images/Services/AnniversaryPlanning.jpg";
import BabyShowerPlanning from "../Images/Services/Baby Shower Planning.jpg";
import Catering from "../Images/Services/catering events.webp";
import ChildrensParty from "../Images/Services/Children's Party.jpg";
import CorporateEvent from "../Images/Real Images/CorporateEvent.png";
import MarketingEvent from "../Images/Services/Marketing Event.jpg";
import RetirementParty from "../Images/Services/Retirement Party Event.jpg";
import SchoolEvent from "../Images/Services/School Events.jpg";
import ThemeParty from "../Images/Services/Theme Party.jpg";
import EngagementEvent from "../Images/Services/engagement event.jpg";
import EngagementParty from "../Images/Services/engagement Party.jpg";
import GraduationParty from "../Images/Services/Garduation-Party.jpg";
import StagPartyPlanning from "../Images/Services/Stag Party.jpg";
import CountrysideWedding from "../Images/Services/Countryside Wedding.jpg";
import OutdoorWedding from "../Images/Services/Outdoor Wedding.jpg";
import VowRenewalSeremony from "../Images/Services/Vow Renewal Seremony.jpg";
import WeddingBanquets from "../Images/Services/Wedding Banquets.png";
import WeddingOffciant from "../Images/Services/Wedding Offciant.webp";


const ServiceData = [
    {
        id: 1,
        ServiceName: "Wedding",
        ServiceSubName:"Palnner",
        ServiceImage: WeddingPalner,
    },
    {
        id: 2,
        ServiceName: "Special",
        ServiceSubName:"Events",
        ServiceImage: BirthdayPlanner,
    },
    {
        id: 3,
        ServiceName: "Birthday",
        ServiceSubName:"Party",
        ServiceImage: BirthdayParty,
    },
    {
        id: 4,
        ServiceName: "Conference",
        ServiceSubName:"Event",
        ServiceImage: ConferenceEvent,
    },
    {
        id: 5,
        ServiceName: "Anniversary",
        ServiceSubName:"Planning",
        ServiceImage: AnniversaryPlanning,
    },
    {
        id: 6,
        ServiceName: "Baby Shower",
        ServiceSubName:"Planning",
        ServiceImage: BabyShowerPlanning,
    },
    {
        id: 7,
        ServiceName: "Catering",
        ServiceImage: Catering,
    },
    {
        id: 8,
        ServiceName: "Children's",
        ServiceSubName:"Party",
        ServiceImage: ChildrensParty,
    },
    {
        id: 9,
        ServiceName: "Corporate",
        ServiceSubName:"Event",
        ServiceImage: CorporateEvent,
    },
    {
        id: 10,
        ServiceName: "Marketing",
        ServiceSubName:"Event",
        ServiceImage: MarketingEvent,
    },
    {
        id: 11,
        ServiceName: "Retirement",
        ServiceSubName:"Party",
        ServiceImage: RetirementParty,
    },
    {
        id: 12,
        ServiceName: "School",
        ServiceSubName:"Event",
        ServiceImage: SchoolEvent,
    },
    {
        id: 13,
        ServiceName: "Theme",
        ServiceSubName:"Party",
        ServiceImage: ThemeParty,
    },
    {
        id: 14,
        ServiceName: "Engagement",
        ServiceSubName:"Event",
        ServiceImage: EngagementEvent,
    },
    {
        id: 15,
        ServiceName: "Engagement",
        ServiceSubName:"Party",
        ServiceImage: EngagementParty,
    },
    {
        id: 16,
        ServiceName: "Graduation",
        ServiceSubName:"Party",
        ServiceImage: GraduationParty,
    },
    {
        id: 18,
        ServiceName: "Stag Party",
        ServiceSubName:"Planning",
        ServiceImage: StagPartyPlanning,
    },
    {
        id: 19,
        ServiceName: "Countryside",
        ServiceSubName:"Wedding",
        ServiceImage: CountrysideWedding,
    },
    {
        id: 20,
        ServiceName: "Outdoor",
        ServiceSubName:"Wedding",
        ServiceImage: OutdoorWedding,
    },
    {
        id: 21,
        ServiceName: "Vow Renewal",
        ServiceSubName:"Seremony",
        ServiceImage: VowRenewalSeremony,
    },
    {
        id: 22,
        ServiceName: "Wedding",
        ServiceSubName:"Banquets",
        ServiceImage: WeddingBanquets,
    },
    {
        id: 23,
        ServiceName: "Wedding",
        ServiceSubName:"Offciant",
        ServiceImage: WeddingOffciant,
    },
];


export default ServiceData;