import React, { useLayoutEffect } from 'react';
import "../CSS/AboutUs.css";
import Sin from "../Images/Mayanka Sin f.png";
import AboutImage from "../Images/about.jpg";
import Footer from "../Component/Footer";
import Team1 from "../Images/Mayank1.jpg";
import Team2 from "../Images/team2.jpg";
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>

      {/* //////////////////////////////////// Section 1 ///////////////////////////// */}

      <section className='Aboutus-Section-1'>
        <div className='AboutUs-Banner'>
          <h1>
            <Bounce top cascade>
              About Us
            </Bounce>
          </h1>
        </div>
      </section>


      {/* //////////////////////////////////// Section 2 ///////////////////////////// */}
      {/* //////////////////////// Home-Section 3 ////////////////////////////  */}



      <section className="Home-section-3">
        <div className="container">
          <div className="row">

            <div className="col-md-6 order-sm-1 order-2">
              <div className="Home-AboutUs">
                <Slide left>
                  <div className="Home-About-Text">
                    about us
                  </div>
                </Slide>
                <Slide right>
                  <div className="Home-About-Info">
                    Whatever
                    <span>Events</span>
                  </div>
                </Slide>
                <div className="home-AboutUs-Para">
                  <p>
                    <Zoom cascade>
                      We’ve been creating memorable event experiences that connect and inspire, and engage and transform organizations. As We are known as best event planner and management company in Mumbai. We are working for weddings, birthday parties, destination wedding, corporate events,and many events With a customer-first mindset and a hospitality focus, we have an unmatched breadth and depth of event production services to support our customers through every step in the event planning and management process.
                    </Zoom>
                  </p>
                  <p>
                    <Zoom cascade>
                      Our full-planning service handles the entire scope of your event like luxurious wedding, corporate events and surprise birthday party including all design, production management, and logistics. From the minute details to the major decisions, our team of experienced planners will ensure your event goes according to plan with full and transparent communication. From the very first meeting, we’ll turn your ideas into a vision board to develop a creative concept you’ll love, and take over the budgeting and payment tracking.
                    </Zoom>
                  </p>
                </div>
                <hr></hr>
                <div className="HS-3-Sin">
                  <div className="Digital-Sin-name">
                    <div className="Digital-Sin-Image">
                      <img src={Sin} alt="" />
                    </div>
                    <div className="Degital-name">
                      <h4>
                        <Zoom cascade>
                          Mayank kumar
                        </Zoom>
                      </h4>
                      <h5>
                        <Zoom cascade>
                          Event Planner
                        </Zoom>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 order-sm-2 order-1">
              <div className="Home-About-Image-con">
                <div className="Home-About-Image">
                  <Zoom>
                    <img src={AboutImage} alt="" />
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* /////////////////////////////////// Section 3 ///////////////////////////////// */}

      <section className='AboutUs-Section-3'>
        <div className='container'>
          <div className='AboutUs-Section-Con'>
            <div className='AS-3-Text-con'>
              <Slide left>
                <div className="AboutUs-Text">
                  OUR SENIOR
                </div>
              </Slide>
              <Slide right>
                <div className="AboutUs-Info">
                  Events
                  <span>Team</span>
                </div>
              </Slide>
            </div>

            <div className='Team-Member'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='Team-Member-Image-con'>
                    <div className='Team-Member-Image'>
                      <img src={Team1} alt="" />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div class="content">
                    <div class="cont text-left">
                      <h4 class="text-left"><span class="text-color-red">Mayank</span> | Event Planner</h4>
                      <div class="section-text">
                        <p></p>
                        <p>
                          <Zoom cascade>
                            A Passionate Event Person right from his college days is Founder of the Company(Mayank kumar). He is the Brain behind the Company formation. </Zoom>
                        </p>
                        <p>
                          <Zoom cascade>
                            His is passionate about events and take each one personally. His passion and dedication, combined with the experience of  in-house event enables us to exceed client expectations.khara yar tu evdha kaHis vision has always been valuing customers and commitment to deliver beyond expectations.
                          </Zoom>
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className='Team-Member'>
                <div className='row'>
                  <div className='col-md-6 order-md-1 order-2'>
                    <div class="content">
                      <div class="cont text-left">
                        <h4 class="text-left"><span class="text-color-red"> Ambrish </span> | Event Planner</h4>
                        <div class="section-text">
                          <p></p>
                          <p>
                            <Zoom cascade>
                              Wedding inilla duiman at elit finibus viverra nec a lacus in the nesuzin sinem misuscit non sesue in miss the aucan volus.
                            </Zoom>
                          </p>
                          <p>
                            <Zoom cascade>
                              Event nissuam nestibulum duru quam nec odio elementum ceisue the misso varius natoque penatibus et magnis dis monte. Donec tuis in the velition florya id velit ac in the miss arcu posuere blane.
                            </Zoom>
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 order-md-2 order-1'>
                    <div className='Team-Member-Image-con'>
                      <div className='Team-Member-Image'>
                        <img src={Team2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////////// Section 4 ///////////////////////////////// */}

      <section className='AboutUs-Section-4'>
        <div className='AboutUs-Section-4-text'>
          <Bounce>
            <h2>OUR VALUES</h2>
          </Bounce>
          <Zoom>
            <h4>The basis of our decisions and work philosophy</h4>
          </Zoom>
          <div className='dotest-Style'>
            <span></span>
          </div>
        </div>
        <div className='container'>
          <div className='values-Section-4'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='QUALITY-con'>
                  <Bounce>
                    <h2>
                      QUALITY
                    </h2>
                  </Bounce>
                  <p>
                    <Zoom cascade>
                      Our reputation and expertise, combined with a proven track record of outstanding service, affords our customers a stress-free and positive experience throughout the lifecycle of their programs.
                    </Zoom>
                  </p>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='QUALITY-con'>
                  <Bounce>
                    <h2>
                      CREATIVITY
                    </h2>
                  </Bounce>
                  <p>
                    <Zoom cascade>
                      The whatever event Company delivers a strategic, creative, turnkey solution for live and virtual meeting and event planning services.
                    </Zoom>
                  </p>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='QUALITY-con'>
                  <Bounce>
                    <h2>
                      RESULTS
                    </h2>
                  </Bounce>
                  <p>
                    <Zoom cascade>
                      We are process-driven, results-oriented, and bottom-line focused, taking time to listen to our customers’ needs and translate them into successful outcomes.
                    </Zoom>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default AboutUs