import React from 'react';
import { Link } from "react-router-dom";
import "../CSS/Footer.css"
import FooterLogo from "../Images/WHATEVER EVENTS LOGO.png";

import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { RiWhatsappLine, RiFacebookFill, RiInstagramFill } from 'react-icons/ri';

const Footer = () => {
    return (
        <>
            <section className='Footer-Section'>
                <div className='container'>
                    <div className='Footer-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Footer-Logo-con'>
                                    <div className='Footer-Image'>
                                        <img src={FooterLogo} alt="" />
                                    </div>
                                    <p>
                                        Your dream is our vision, we are passionate about creating a unique event that brings your dreams to life.To make our clients event planning processes simple by offering reliable and impactful services.The experience our clients get from working with us is always as important as the experience we deliver to the events audiences.
                                    </p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-Mid-Col'>
                                    <div className='nav_menu-2'>
                                        <h3 className='footer-title'>
                                            Explore
                                        </h3>
                                        <div className='menu-footer-menu-container'>
                                            <ul className='menu-footer-menu'>
                                                <li className='menu-item'>
                                                    <Link to='/'>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/Service'>
                                                        Service
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/Gallery'>
                                                        Gallery
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/AboutUs'>
                                                        About
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/ContactUs'>
                                                        Contact
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-column'>
                                    <div className='widget'>
                                        <h3>Get in touch</h3>
                                        <div className='footer-contact'>
                                            <p className='footer-contact-text'>
                                                <a href='https://www.google.com/maps/place/Whatever+events/@19.134346,72.82646,11z/data=!4m6!3m5!1s0x3be7b7ac1ba921cb:0xc852cf985b354903!8m2!3d19.1343458!4d72.82646!16s%2Fg%2F11sd6_6w3h?hl=en' target='blank'>
                                                    Office No 411, MOHID HEIGHTS, opposite Mhada Road, near kokilaben hospital signal, Suresh Nagar,<br></br>
                                                    Andheri West, Mumbai, Maharashtra 400053
                                                </a>
                                            </p>
                                            <div className='footer-contact-info'>
                                                <p className='footer-contact-phone'>
                                                    <span className='ti-headphone-alt'>
                                                        <TfiHeadphoneAlt />
                                                    </span>
                                                    <a href="tel:6388-547-369">6388-547-369</a>
                                                </p>
                                                <p className='footer-contact-mail'>
                                                    <a href="mailto: whateverevents63@gmail.com">
                                                        whateverevents63@gmail.com
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='Footer-Social-Media'>
                                                <a href='https://wa.me/7039212103?text=' target="blank"><RiWhatsappLine /></a>
                                                <a href='https://www.facebook.com/profile.php?id=100088041936338&mibextid=LQQJ4d' target="blank"><RiFacebookFill /></a>
                                                <a href='https://www.instagram.com/whatever.events/' target="blank"><RiInstagramFill /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Copy-Right'>
                        <div className='Contact-Us-Copy'>
                            © Copyright
                            <strong>
                                <span style={{ color: "#d6a063" }}> WHATEVER EVENTS </span>
                            </strong>
                            . All Rights Reserved
                        </div>
                        <div className="credits">
                            Designed by 
                            <a href="https://skdm.in/" style={{ color: "#d6a063" }}>
                                Shree Krishna Digital
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer